(function ($) {
    var pauseStickyCheck = false;

    stickyHeaderCheck();

    $(window).scroll(function () {
          stickyHeaderCheck();
    });

    // $('.main-menu-toggle').click(function(e) {
    //     forceSticky();
    // });

    function stickyHeaderCheck() {
        if (pauseStickyCheck) return;

        $('header').toggleClass('is-sticky', $(document).scrollTop() > 120);
    }

    function forceSticky() {
        pauseStickyCheck = true;

        window.setTimeout(function() {
            pauseStickyCheck = false;
        }, 850);
    }
})(jQuery);
