// Scripting for sliders or carousels (Baytek gutenblocks plugin includes a slider block that uses slick slider)
(function($){

	// Simple Slider Block using Slick.js
	$('.carousel-slider').slick({
		arrows: true,
		dots: true,
		slidesToScroll: 1,
		slidesToShow: 4,
		infinite: true,
		// variableWidth: true,
		draggable: true,
		prevArrow: '<button type="button" class="slick-prev slick-arrow"><i class="fal fa-chevron-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next slick-arrow"><i class="fal fa-chevron-right"></i></button>',
		responsive: [{
			breakpoint: 1600,
			settings: {
				slidesToShow: 3
			}
		},{
			breakpoint: 1000,
			settings: {
				slidesToShow: 2
			}
		},{
			breakpoint: 500,
			settings: {
				slidesToShow: 1
			}
		}]
	});



	// If you need multiple Slider Blocks on one page use this instead of above:
	///// 1. Create a Group Block with the class: carousel-wrapper
	///// 2. Add the Slider Block in this new Group
	///// 3. Below the Slider and in the same Group add the Buttons Block for the custom Arrows
	///// 4. Add 2 buttons:  1st will have '.left' class. 2nd will have '.right' class.
	// $('.carousel-wrapper .wp-block-group__inner-container .carousel-slider').each(function(){
	// 	var slickIndividual = $(this);
	// 	slickIndividual.slick({
	// 		slidesToShow: 3,
	// 		slidesToScroll: 1,
	// 		arrows: true,
	// 		dots: false,
	// 		nextArrow: slickIndividual.next().find('.right'),
	// 		prevArrow: slickIndividual.next().find('.left'),
	// 		draggable: true,
	// 		infinite: true,
	// 	  	responsive: [
	// 	  		{
	// 				breakpoint: 720,
	// 				settings: {
	// 					slidesToShow: 2
	// 				}
	// 			}
	// 		]
	// 	});
	// });

	/**
	 * Testimonials slider
	 */
	$('.testimonial-slider > div').slick({
		arrows: true,
		dots: true,
		slidesToScroll: 1,
		slidesToShow: 1,
		autoplay: false,
		infinite: true,
		// draggable: true,
		prevArrow: '<a class="button slick-arrow slick-prev" aria-label="Previous"><i class="fal fa-long-arrow-left"></i></a>',
		nextArrow: '<a class="button slick-arrow slick-next" aria-label="Next"><i class="fal fa-long-arrow-right"></i></a>'
	});

	// Testimonial Sliders
	$('.testimonial-test-slider > .wp-block-group__inner-container').slick({
		arrows: true,
		dots: false,
		slidesToScroll: 1,
		slidesToShow: 1,
		infinite: true,
	    autoplay: false,
	    autoplaySpeed: 3000,
	    centerMode: false,
		prevArrow: '<div class="slick-arrow wp-block-buttons arrow top-arrow left-arrow"><div class="wp-block-button"><span class="wp-block-button__link"></span></div></div>',
		nextArrow: '<div class="slick-arrow wp-block-buttons arrow top-arrow right-arrow"><div class="wp-block-button"><span class="wp-block-button__link"></span></div></div>',
	});

	/**
	 * Related content slider
	 */
	$('.related-content-slider > div').slick({
		arrows: true,
		appendArrows: '.related-content-slider-arrows',
		dots: false,
		slidesToScroll: 3,
		slidesToShow: 3,
		infinite: true,
		draggable: true,
		prevArrow: '<a class="button slick-arrow slick-prev" aria-label="Previous"><i class="fal fa-long-arrow-left"></i></a>',
		nextArrow: '<a class="button slick-arrow slick-next" aria-label="Next"><i class="fal fa-long-arrow-right"></i></a>',
		responsive: [{
			breakpoint: 720,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		},{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}]
	});

	/**
	 * Team members slider
	 */
	$('.team-members-slider > div').slick({
		arrows: true,
		appendArrows: '.team-members-slider-arrows',
		dots: false,
		slidesToScroll: 4,
		slidesToShow: 4,
		infinite: true,
		draggable: true,
		prevArrow: '<a class="button slick-arrow slick-prev" aria-label="Previous"><i class="fal fa-long-arrow-left"></i></a>',
		nextArrow: '<a class="button slick-arrow slick-next" aria-label="Next"><i class="fal fa-long-arrow-right"></i></a>',
		responsive: [{
			breakpoint: 1100,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3
			}
		},{
			breakpoint: 720,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		},{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}]
	});

	/**
	 * Awards slider
	 */
	 $('.awards-slider > div').each(function() {
	 	$(this).slick({
			arrows: true,
			appendArrows: $(this).parent().parents('.wp-block-group').find('.awards-slider-arrows').first(),
			dots: false,
			slidesToScroll: 4,
			slidesToShow: 4,
			infinite: true,
			draggable: true,
			prevArrow: '<a class="button slick-arrow slick-prev" aria-label="Previous"><i class="fal fa-long-arrow-left"></i></a>',
			nextArrow: '<a class="button slick-arrow slick-next" aria-label="Next"><i class="fal fa-long-arrow-right"></i></a>',
			responsive: [{
				breakpoint: 1100,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},{
				breakpoint: 720,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}]
		});
	 });

	/**
	 * Historical Occurances slider
	 */
	 $('.infographic-wrapper').slick({
		arrows: true,
		appendArrows: '.historical-slider-arrows',
		dots: false,
		slidesToScroll: 1,
		slidesToShow: 4,
		infinite: false,
		draggable: true,
		prevArrow: '<a class="button slick-arrow slick-prev" aria-label="Previous"><i class="fal fa-long-arrow-left"></i></a>',
		nextArrow: '<a class="button slick-arrow slick-next" aria-label="Next"><i class="fal fa-long-arrow-right"></i></a>',
		responsive: [{
			breakpoint: 980,
			settings: {
				slidesToShow: 3,
			}
		},{
			breakpoint: 720,
			settings: {
				slidesToShow: 2,
			}
		},{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
			}
		}]
	});


// Home Industries Slider

	$('.industries-slides > .wp-block-group__inner-container').slick({
		arrows: true,
		appendArrows: '.industries-slider-arrows',
		dots: false,
		slidesToScroll: 1,
		slidesToShow: 4,
		infinite: true,
		draggable: true,
		prevArrow: '<a class="button slick-arrow slick-prev" aria-label="Previous"><i class="fal fa-long-arrow-left"></i></a>',
		nextArrow: '<a class="button slick-arrow slick-next" aria-label="Next"><i class="fal fa-long-arrow-right"></i></a>',
		responsive: [{
			breakpoint: 980,
			settings: {
				slidesToShow: 2,
			}
		},{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
			}
		}]
	});

})(jQuery);