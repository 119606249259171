// Scripting of modals and interactions
(function ($) {
	$(document).on('click', 'a.open-popup-link, .open-popup-link > a', function (e) {
		e.stopPropagation();
		e.preventDefault();

		if (!$(this).hasClass('mp-init')) {
			var $modal = $($(this).attr('href')).first();
			var hasVideo = $modal.find('iframe');

			$(this).magnificPopup({
				type: 'inline',
				// modal: true,
				closeOnBgClick: true,
				closeOnContentClick: false,
				showCloseBtn: true,
				closeBtnInside: true,
				enableEscapeKey: true,
				callbacks: {
					open: function () {
						if (hasVideo) {
							$modal.find(".wp-has-aspect-ratio iframe").each(function () {
								var addautoplay = $(this).attr("src") + "?autoplay=1";
								$(this).attr("src", addautoplay);
							});
						}
					},
					close: function () {
						if (hasVideo) {
							$modal.find(".wp-has-aspect-ratio iframe").each(function () {
								var removeautoplay = $(this).attr("src").replace("?autoplay=1", "");
								$(this).attr("src", removeautoplay);
							});
						}
					}
				}
			}).addClass('mp-init').click();
		}
	});

	//Open the popup from the url, if it exists
	if (window.location.hash && $(window.location.hash).length) {

		// If popup class is on the target, pop it like its hot.
		if ($(window.location.hash).hasClass('standard-modal')) {
			var $modal = $(window.location.hash);
			var hasVideo = $modal.find('iframe');

			$.magnificPopup.open({
				items: {
					src: window.location.hash,
				},
				type: 'inline',
				closeOnBgClick: true,
				closeOnContentClick: false,
				showCloseBtn: true,
				closeBtnInside: true,
				enableEscapeKey: true,
				callbacks: {
					open: function () {
						if (hasVideo) {
							$modal.find(".wp-has-aspect-ratio iframe").each(function () {
								var addautoplay = $(this).attr("src") + "?autoplay=1";
								$(this).attr("src", addautoplay);
							});
						}
					},
					close: function () {
						if (hasVideo) {
							$modal.find(".wp-has-aspect-ratio iframe").each(function () {
								var removeautoplay = $(this).attr("src").replace("?autoplay=1", "");
								$(this).attr("src", removeautoplay);
							});
						}
					}
				}
			});
		}
	}

	$('.search-trigger a').magnificPopup({
		type: 'inline',
		// modal: true,
		closeOnBgClick: false,
		closeOnContentClick: false,
		showCloseBtn: true,
		closeBtnInside: true,
		enableEscapeKey: false,
		autoFocusLast: false,
		focus: '#search_input_modal',
		closeMarkup: '<button class="mfp-close" type="button" title="Close"><i class="fal fa-times"></i></button>',
		callbacks: {
			open: function() {
				$('body').addClass('no-scroll');
			},
			close: function() {
				$('body').removeClass('no-scroll');
			}
		}
	});

	$(document).on('click', 'button.mfp-close', function(e) {
		$.magnificPopup.close();
	});
})(jQuery);
