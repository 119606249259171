// Govern the search popup
(function($){
	$('.search-open a').click(function(e) {
		$('#search-form').addClass('open');

		$('#header-search-input').focus();
	});

	$('.menu.secondary li a, .menu.primary li a').hoverIntent({
		over() {
			$('#search-form').removeClass('open');

		},
		sensitivity: 5,
		timeout: 300
	});
})(jQuery);