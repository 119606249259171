// A place to keep your site wide scripted animations.
(function($){

    if ($('.number-up').length || $('.number-down').length) {
        // About page Increasing Numbers
        var numberUp = document.querySelector('.number-up');
        var numberDown = document.querySelector('.number-down');
        var hasEntered = false;

        window.addEventListener('scroll', (e) => {
        	var shouldAnimate = (window.scrollY + window.innerHeight) >= numberUp.offsetTop;
        	var shouldAnimate = (window.scrollY + window.innerHeight) >= numberDown.offsetTop;

        	if (shouldAnimate && !hasEntered) {
          	hasEntered = true;
            
            $('.number-up .value').each(function () {
            	$(this).prop('Counter',0).animate({
                Counter: $(this).text()
            	}, {
                duration: 2000,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now));
                }
           		});
            });

            $('.number-down .value').each(function () {
            	$(this).prop('Counter',2022).animate({
                Counter: $(this).text()
            	}, {
                duration: 2000,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now));
                }
           		});
            });

          }
        });
    }

})(jQuery);
